.Input {
  border: 1px solid #4f5f56;

  /* border-radius: 4px; */
  width: 352px;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  /*padding-left: 10px;*/
}
.Input:focus {
  outline: none;
}

input:valid {
  /* background-color: palegreen; */
}

input:invalid {
  background-color: white;
}
