.auth_container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  min-height: 880px;

  position: absolute;
  top: 0px;
  bottom: 0px;
  overflow: auto;
}

.form_auth_block {
  /* width: 535px; */
  height: 700px;

  margin: auto;
  background-color: white;
  margin-top: 10px;
  /* background-image: url("../images/pattern_3.png"); */
  /* background-size: 108%; */
  /* background-position: bottom;
    background-repeat: repeat-x; */
  /* border-radius: 4px; */
  align-items: center;

  /* box-shadow: 0 0 10px  rgba(0,0,0,0.2); */
}

.logo {
  background: url('../images/loginLogo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 350px;
  height: 121px;
  margin: auto;
  margin-top: 40px;
}

.footer {
  position: absolute;
  bottom: 0px;
}

.footer_img {
  background: url('../images/login_view_img1.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 313px;
  height: 222px;
  margin: auto;
  /* margin-top: 70px; */
  /* 
    position: absolute;
    bottom: 0px; */
}
.footer_text {
  font-size: 11px;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  position: relative;
  bottom: 12px;
  color: #4f5f56;
}

.header_info {
  width: 350px;
  display: block;
  margin: auto;
  margin-top: 35px;
  /* color:  #505F56; */
  font-size: 12px;
  font-family: 'TTNorms-regular';
  font-weight: bold;
  /*font-family: "PlayfairDisplay";*/
  color: #4f5f56;
  text-align: center;
}
.form_auth_block_header {
  display: block;
  margin: auto;
  margin-top: 35px;
  /* color:  #505F56; */
  font-size: 40px;
  font-family: 'PlayfairDisplay';
  /*font-family: "PlayfairDisplay";*/
  color: #4f5f56;
  text-align: center;
}

/*.form_auth_block_content{*/
/*    margin: auto;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

.fogot_link {
  display: flex;
  justify-content: left;
  margin-bottom: 15px;

  margin-top: 10px;
  /* font-family: "TTNorms-regular"; */
  /*margin: 20px 0px;*/
  /*display: flex;*/
  /*justify-content: center;*/
}

.fogot_link > a {
  text-decoration: none;
  /* font-family: sans-serif; */
  font-size: 14px;

  color: #c9b499;
}

.fogot_link > a:visited,
.info_text a:visited {
  color: #c9b499;
}

.wrong_data {
  margin: 0px 0px;
  display: flex;
  justify-content: center;
  color: rgb(221, 4, 4);
}

.wrong_password {
  display: flex;
  justify-content: right;
  /* margin-bottom: 10px; */
  text-decoration: none;
  font-family: sans-serif;

  font-size: 16px;
  color: rgb(221, 4, 4);
}

.center {
  display: flex;
  justify-content: center;
}

.form_auth_login_block p,
.form_auth_password_block p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;

  /* font-family: "Segoe UI Semibold"; */

  color: #526158;
}

.textfield {
  border: 1px solid #4f5f56;

  /* border-radius: 4px; */
  width: 350px;
  height: 40px;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 10px;
  margin-top: 10px;
  /*padding-left: 10px;*/
}

.signin_btn,
.register_btn {
  display: block;
  width: 350px;
  height: 44px;
  margin: 5px auto;

  border: none;
  cursor: pointer;
  background-color: #4f5f56;
  color: white;
  font-size: 20px;
}

.register_btn {
  background-color: #f7decc;
  color: #4f5f56;
  font-weight: bold;
}

.info_text {
  padding-top: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  position: relative;
  bottom: 12px;
  color: #4f5f56;
}

.form_auth_login_block:hover,
.form_auth_password_block:hover {
  border-color: cornflowerblue;
}

.signin_btn:hover,
.register_btn:hover {
  background-color: grey;
}
.signin_btn:active,
.register_btn:active {
  background-color: var(--color-primary);
}
