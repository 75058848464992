.container_external {
  display: flex;
  justify-content: center;
  margin: 0px auto;
  /* background-color: red; */

  min-height: 750px;

  min-width: var(--min-page-width);
  max-width: var(--min-page-width);
  position: absolute;
  bottom: 20px;
  top: 100px;
  left: 0;
  right: 0;
}

.container_mid {
  min-width: var(--min-page-width);
  max-width: var(--min-page-width);

  margin-bottom: 10px;

  display: flex;
  justify-content: center;
  gap: 10px;
}

.info_container {
  width: 70%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Параметры тени */
  background-image: url('../../images/pattern_4.png');

  background-repeat: repeat-y;
  background-size: 50%;
}

.user_logo {
  display: flex;
  margin-top: 20px;
  margin-left: 50px;
}

.user_logo div {
  margin: auto 10px;
}

.user_logo div * {
  margin-top: 5px;
  margin-bottom: 5px;
}

.user_logo img {
  height: 70px;
  width: 70px;
}

.info_body {
}

.edit_form > div {
  display: flex;
  margin: 10px 20px;
}

.user_data_container {
  width: 100%;
}
.center {
  display: flex;
  justify-content: center;
}

.center > div {
  margin: 10px auto;
}

.left {
  display: flex;
  justify-content: left;
}

.left > div {
  margin: 10px 0px 10px 35px;
}

.form_save_button {
  display: block;
  width: 204px;
  height: 44px;
  margin: 40px auto 30px;

  border-radius: 4px;

  border: none;
  cursor: pointer;
  background-color: #8ab69d;
  color: white;

  font-size: 16px;
}

.list_container {
  width: 30%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Параметры тени */
  height: 100%;
  padding: 10px;
  background-image: url('../../images/pattern_5.png');

  background-repeat: repeat-y;
  background-size: 100%;
}

.user_filter {
  /*margin: 20px 10px;*/
  /* height: 20%; */
  margin-left: 10px;
}

.user_filter > div {
  /*margin: 20px 10px;*/

  margin-bottom: 5px;

  display: flex;

  gap: 10px;
}

.user_filter > div > span {
  width: 90px;
}

.user_filter > div > input[type='text'] {
  margin-left: 5px;
  width: 70%;
}

.user_filter > div > select {
  margin-left: 5px;
  width: 70%;
}

.users_list {
  border: 1px solid #4f5f56;
  margin-top: 20px;
  /*margin: 10px 10px;*/
  /*background: #8f8f8f;*/
  /* height: 90%; */

  /* max-height: 80%; */
  height: 70%;
  overflow-x: auto;
}
.users_list ul {
  list-style: none;
}

.users_list ul li {
  list-style: none;
  margin: 10px 10px;
}

.user_row > img {
  height: 40px;
  width: 40px;
}

.user_row {
  display: flex;
  border-radius: 4px;
  border: 2px solid #ededed;
  background-color: white;
  padding: 5px;
}

.user_row_selected {
  background-color: grey;
}

.user_row:hover {
  cursor: pointer;
}

input[type='text']:focus {
  outline: none;
}

.create_user {
  width: 70%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Параметры тени */
  background-image: url('../../images/pattern_4.png');

  background-repeat: repeat-y;
  background-size: 50%;
}

.header {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-family: 'PlayfairDisplay';
  font-size: 22px;
  color: #4f5f56;
}

.add_user_button {
  background-image: url(../../icons/add_user_icon.png);
}
.add_user_button:hover {
  background-image: url(../../icons/add_user_icon_grn.png);
}

.icon_button {
  /* size: 30px; */
  display: flex;
  justify-content: left;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: 5px 2px;

  border: 0;
  background-color: transparent;
  height: 30px;
  /* width: 80px; */
  padding: 2px;
  border: 1px solid transparent;
  border-radius: 0px;
  padding-left: 35px;
  padding-right: 10px;
  align-items: center;
  color: #c9b499;
  margin-bottom: 10px;
}

.icon_button:hover {
  /* background-color: #86978d; */

  /* border: 1px solid #86978d; */
  color: #4f5f56;
  /* border-radius: 15px; */
  cursor: pointer;
}
.icon_button:active {
  /* background-color: #4f5f56; */
  /* border: 1px solid #4f5f56; */
  color: #a8b1ac;
}

.btnCreateUser {
  display: block;
  width: 350px;
  height: 44px;
  margin: 5px auto;
  margin-top: 50px;

  border: none;
  cursor: pointer;
  background-color: #4f5f56;
  color: white;
  font-size: 20px;
}

.btnCreateUser:hover {
  background-color: grey;
}
.btnCreateUser:active {
  background-color: var(--color-primary);
}
