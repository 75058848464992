.claims {
  margin: 0px auto;
  /* background-color: red; */

  min-height: 750px;

  min-width: var(--min-page-width);
  max-width: var(--min-page-width);
  position: absolute;
  bottom: 20px;
  top: 100px;
  left: 0;
  right: 0;
}

.header {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-family: 'PlayfairDisplay';
  font-size: 22px;
  color: #4f5f56;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  min-height: 750px;

  min-width: var(--min-page-width);
  max-width: var(--min-page-width);
  position: absolute;

  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
}

.claimInfo {
  /* min-width: 500px; */
  padding: 5px;
  /* border: 1px solid #4F5F56; */
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  width: 65%;
}

.claimInfo * {
  margin-top: 10px;
}

.claimInfo .text {
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #4f5f56;
  padding: 10px;
}

.claimList {
  width: 35%;
  height: 100%;
  border: 1px solid#4F5F56;
  padding-left: 5px;
  padding-top: 5px;
}
.claimList ul {
  margin: 5px;
  height: 95%;
  overflow-y: auto;
  /* display:flex;
    justify-content: center; */
}
.claim {
  display: flex;
  justify-content: space-between;
  border: 1px solid#4F5F56;
  margin: 5px;
  margin-top: 0px;
  width: 400px;
  padding: 10px;
}

.claimSelected {
  background-color: #8c9992;
}

.claim:hover {
  background-color: #4f5f56;
  cursor: pointer;
}

.claim:active {
  background-color: #8c9992;
  cursor: pointer;
}
.claim .claimLeft {
}

.claim .claimRight {
}
