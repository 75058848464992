.menu_container {
  /* max-width: 1370px; */
  display: flex;
  justify-content: center;

  margin: 0 auto;

  background-color: var(--color-primary);
  background-image: url('../images/header_image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  width: 100%;
  min-width: var(--min-page-width);
  /* max-width: var(--min-page-width); */
}

.menu_container_inner {
  width: 100%;
}

.menu_container_content {
  height: 80px;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid var(--color-text-1); */
  align-items: center;
  padding-right: 30px;
  min-width: var(--min-page-width);
}

.logo_container {
  display: flex;
  align-items: center;
}

.logo {
  margin-left: 20px;
  background: url('../images/logo_koreanika_1.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
  width: 200px;
}

.logo_text {
  color: white;
  font-family: 'TTNorms-regular';
  font-size: 14px;
  width: 220px;
}

.user_info_container {
  display: flex;
  padding-left: 10px;
  gap: 20px;
}
.user_info_container img {
  height: 60px;
  width: 60px;
}
.user_info_1 {
  align-items: center;
  margin: auto;
}

.user_info_1 .user_name {
  font-family: 'TTNorms-regular';
  color: white;
  font-size: 16px;
}
.user_info_1 .user_role {
  font-family: 'TTNorms-Light';
  color: white;

  font-size: 12px;
  margin-top: 5px;
}

.user_info_1 .user_info {
  font-size: 12px;
}

.user_info_btn {
  align-items: center;
  margin: auto;
}
.profileLink,
.logoutLink {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 5px;
}

.profileLink img,
.logoutLink img {
  width: 16px;
  height: 16px;
}

.profileLink:hover,
.logoutLink:hover {
  cursor: pointer;
}

.user_profile,
.user_logout {
  display: block;
  text-decoration: none;
  font-size: 12px;
}

.user_logout {
  /* margin-top: 5px; */
}

.user_profile:visited,
.user_logout:visited {
  color: #c9b499;
}
.user_profile:active,
.user_logout:active {
  color: grey;
}

.menu {
  display: flex;
  text-align: center;
  gap: 20px;
}
.menuColumn a {
  display: block;
}

.menu_items {
  display: flex;
  align-items: center;
}

/* .menu_items > ul{
    display: flex;
    list-style-type: none;
    text-align: center;
    gap: 10px; 
} */

.menu_btn {
  text-decoration: none;
  color: #8bb59d;
  padding: 5px 5px;

  border-color: transparent;
  font-size: 16px;
  background-color: transparent;
}
.menu_btn.a:visited {
  color: #8bb59d;
}

.menu_btn.highlight {
  color: white;
  /* font-weight: bold; */
}

.menu_items > ul > li > button:hover {
  color: var(--color-dark);
  cursor: pointer;
}

.header {
  margin-top: 10px;
  text-align: center;
  color: var(--color-text-1);
  min-width: var(--min-page-width);
}

.logo:hover {
  cursor: pointer;
}
