body {
  background-color: white;
}

.reg_container {
  display: flex;
  justify-content: center;
  /*height: 420px;*/
  margin: 10px;
}

.form_reg_block {
  /*width: 535px;*/
  /*height: 556px;*/
  margin: auto auto;
  background: white;
  background-size: cover;
  /* border-radius: 4px; */

  background-color: white;
  /* background-image: url("../images/pattern_3.png"); */
  background-size: 108%;
  background-position: bottom;
  background-repeat: repeat-x;
  /* box-shadow: 0 0 10px  rgba(0,0,0,0.2);  */
}

.logo {
  background: url('../images/loginLogo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 350px;
  height: 121px;
  margin: 10px auto;
}
.form_reg_block_header {
  display: block;
  margin: auto;
  margin-top: 35px;
  /* color:  #505F56; */
  font-size: 40px;
  font-family: 'PlayfairDisplay';
  /*font-family: "PlayfairDisplay";*/
  color: #4f5f56;
  text-align: center;
}

.form_reg_block_content {
  display: flex;
}

.form_reg_block_content div {
  margin: 10px 10px 0px 10px;
}

.center {
  display: flex;
  justify-content: center;
}

.forgot_link {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}

.forgot_link > a {
  text-decoration: none;
  font-family: sans-serif;
  font-size: 16px;
}

.reg_field_container {
  margin: 5px auto;
}

.reg_field_container p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;

  font-family: 'Segoe UI Semibold';

  color: #526158;
}

.form_reg_button {
  display: block;
  width: 350px;
  height: 44px;
  margin: 5px auto;
  margin-top: 50px;

  border: none;
  cursor: pointer;
  background-color: #4f5f56;
  color: white;
  font-size: 20px;
}

.role_selector:focus {
  outline: none;
}

.form_reg_login_block:hover,
.form_reg_password_block:hover {
  border-color: cornflowerblue;
}

.form_reg_button:hover {
  background-color: grey;
}
.form_reg_button:active {
  background-color: var(--color-primary);
}
