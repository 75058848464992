.header {
  display: flex;
  justify-content: center;
}
.container_statistics {
  display: flex;
  margin: 10px auto;
  justify-content: center;
  padding: 10px;

  min-width: var(--min-page-width);
  max-width: var(--min-page-width);

  min-height: 700px;
  position: absolute;
  bottom: 20px;
  top: 100px;
  left: 0;
  right: 0;
}

.container_inner {
  /* margin-bottom: 10px; */
}

.preset_buttons {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.preset_buttons > input {
  font-family: 'TTNorms-light';
  width: 100px;
  height: 25px;
  background-color: #4f5f56;
  color: white;
  border-radius: 0px;
  border-color: null;
  font-size: 15px;
  border-width: 0;
  /* display: block;
    width: 350px;
    height: 44px;
    margin: 5px auto;

    border: none;
    cursor: pointer;
    background-color: #4F5F56;
    color: white;
    font-size: 20px; */
}

.preset_buttons > input:hover {
  background-color: #3b3d3c;
  border-color: red;
}

.preset_buttons > input:active {
  background-color: #729b84;
  border-color: red;
}

.filter_onoff {
  /* border-bottom: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter_onoff div input[type='button'] {
  width: 100px;
  height: 20px;
}

.filter_onoff div {
  margin-top: 10px;
  margin-bottom: 0px;
}

.filter_onoff div span:first-of-type {
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
}

.filter_row input[type='date'] + span,
.filter_row input[type='text'] + span {
  padding-left: 10px;
  padding-right: 10px;
}

.filter_row span:first-of-type + span {
  padding-right: 10px;
}

.datepicker {
  text-align: center;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  width: 100px;
}

input[type='text']:focus {
  outline: none;
}

input[type='date']:focus {
  outline: none;
}

/*filter style: */
.container_filter {
  margin-top: 10px;
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.filter {
  display: flex;
  /* justify-content: left; */
}

.filter_row {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* .filter_row input[type=checkbox]{

} */
.filter_column1 {
  width: 600px;
}

.filter_row span:first-of-type {
  margin-left: 10px;
}
.filter_column1 span:first-of-type {
  min-width: 100px;
}
.filter_column1 filter_row span:first-of-type + span {
  margin-left: 10px;
}

.filter_column2 {
  width: 600px;
  margin-left: 50px;
}

.filter_column2 span:first-of-type {
  width: 150px;
}

.updateBtnContainer {
  display: flex;
}

#updateButton {
  width: 100px;
  height: 30px;
}

.table_container_statistics {
  margin-top: 10px;

  max-height: 80%;
  overflow-x: auto;
}

.table_statistics {
  /*border-collapse: collapse;*/
  border-spacing: 0;
}

.table_statistics thead {
  position: sticky;
  top: 0;
  background-color: grey;
}

.table_statistics th {
  border: 2px solid black;
  padding: 1px 5px;
  font-size: 14px;
}

.table_statistics td {
  border: 1px solid black;
  background-color: white;
  padding: 1px 5px;
  text-align: center;
  font-size: 13px;
}

/* Стили для скролла */
.table_container_statistics::-webkit-scrollbar {
  width: 6px;
}
.table_container_statistics::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.table_container_statistics::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 3);
}
