* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'TTNorms-regular';
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url(../fonts/PlayfairDisplay-Regular.ttf);
}

@font-face {
  font-family: 'TTNorms-light';
  src: url('../fonts/TTNorms/TTNorms-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'TTNorms-regular';
  src: url('../fonts/TTNorms/TTNorms-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'TTNorms-medium';
  src: url('../fonts/TTNorms/TTNorms-Medium.otf') format('truetype');
}

:root {
  --min-page-width: 1270px;
  --color-primary: #505f56;
  --color-dark: #363f3a;
  --color-text-1: black;
}

.header {
  font-family: 'Segoe UI Semibold';
  font-size: 24px;
  color: #907757;
  text-align: center;
  padding: 10px 0 10px 0;
}

.center {
  display: flex;
  justify-content: center;
}

.textfield,
.choiseBox {
  border: 1px solid #4f5f56;

  /* border-radius: 4px; */
  width: 352px;
  height: 40px;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 10px;
  /*padding-left: 10px;*/
}

.textfield:focus {
  outline: none;
}

.green-button {
  display: block;
  width: 204px;
  height: 44px;
  /*margin: 40px auto 30px;*/
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: #8ab69d;
  color: white;
  font-size: 16px;
}

.green-button:hover {
  background-color: #9eb3a7;
}

.green-button:active {
  background-color: #47544d;
}
