/* :root {
    --min-page-width: 1270px;
    --color-primary: #505F56;
    --color-dark: #363f3a;
    --color-text-1: black;
  } */

/* @import url("./main.css"); */
.container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.container_mid {
  min-width: 820px;
  max-width: 820px;

  display: flex;
  justify-content: center;
  gap: 10px;
}

.container_info {
  width: 100%;

  /* box-shadow: 0 0 10px  rgba(0,0,0,0.2); */
  /* background-image: url("../images/pattern_4.png"); */

  background-repeat: repeat-y;
  background-size: 50%;
}

.page_header {
  display: block;
  margin: 35px auto;
  /* margin-top: 35px; */
  /* color:  #505F56; */
  font-size: 28px;
  font-family: 'PlayfairDisplay';
  /*font-family: "PlayfairDisplay";*/
  color: #4f5f56;
  text-align: center;
}

.data_body {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}
.password_body {
  display: flex;
  justify-content: left;
  margin: 10px 20px;
}

.user_data_container {
  width: 100%;
  font-family: 'TTNorms-regular';
}

.field_container {
  /* align-items: center; */
}

/* .center > div{
    margin: 10px auto;
} */

.left {
  display: flex;
  justify-content: left;
}

.left > div {
  margin: 10px 0px 10px 35px;
}

.user_image {
  display: flex;
  justify-content: center;
  /* margin: auto; */
}

.user_image img {
  height: 60px;
  width: 60px;
}

.user_image div {
  margin-left: 20px;
  /* margin: auto; */
  color: #4f5f56;

  vertical-align: middle;
}

.user_image div button {
  border: 1px solid black;
  padding: 3px;
}
.user_image div * {
  margin-top: 5px;
  /* display: inline-block; */
}

.toolImage {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.msg_container {
  height: 44px;
  width: 352px;
  background-color: #f7decc;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  visibility: hidden;
}
.msg_container p {
  color: #4f5f56;
}
.msg_container img {
  height: 18px;
  width: 19px;
}

.btn_save_container {
  display: flex;
  justify-content: center;

  margin: 50px 20px 20px 20px;

  /* padding-bottom: 20px; */
}

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

.btn_save_container > input {
  display: block;
  width: 350px;
  height: 44px;
  margin: 5px auto;

  border: none;
  cursor: pointer;
  background-color: #4f5f56;
  color: white;
  font-size: 20px;
}

.btn_change_password_container {
  display: flex;
  justify-content: center;

  margin: 50px 20px 20px 5px;
}

.btn_select_file {
  /* border-radius: 0px; */
  padding: 4px;
  /* border-radius: 0%; */
  border: 1px solid rgb(63, 62, 62);
}

.btn_save_container > input:hover,
.btn_select_file:hover {
  background-color: grey;
}
.btn_save_container > input:active,
.btn_select_file:active {
  background-color: var(--color-primary);
}
