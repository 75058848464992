.app_access {
  margin-top: 10px;
  display: flex;
  justify-content: left;
  width: 352px;
  gap: 20px;
}

.app_access input {
  margin-right: 2px;
}
