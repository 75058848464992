body {
  /*background-color: var(--color-primary);*/
}

.container_forgot_password {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  min-height: 800px;

  position: absolute;
  top: 0px;
  bottom: 0px;
  overflow: auto;
}

.form_block_forgot_password {
  width: 535px;
  height: 500px;
  /* margin: auto auto; */
  background-color: white;
  /* background-image: url("../images/pattern_3.png"); */
  background-size: 108%;
  background-position: bottom;
  background-repeat: repeat-x;
  border-radius: 4px;
  align-items: center;

  /* box-shadow: 0 0 10px  rgba(0,0,0,0.2); */
}

.logo {
  background: url('../images/loginLogo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 350px;
  height: 121px;
  margin: auto;
  margin-top: 100px;
}

.header_info {
  width: 350px;
  display: block;
  margin: auto;
  margin-top: 35px;
  /* color:  #505F56; */
  font-size: 12px;
  font-family: 'TTNorms-regular';
  font-weight: bold;
  /*font-family: "PlayfairDisplay";*/
  color: #4f5f56;
  text-align: center;
}

/*.form_auth_block_content{*/
/*    margin: auto;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

.fogot_link {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  /*margin: 20px 0px;*/
  /*display: flex;*/
  /*justify-content: center;*/
}

.wrong_data {
  margin: 0px 0px;
  display: flex;
  justify-content: center;
  color: rgb(221, 4, 4);
}

.fogot_link > a {
  text-decoration: none;
  font-family: sans-serif;
  font-size: 16px;
}

.form_block_header {
  display: block;
  margin: auto;
  margin-top: 35px;
  /* color:  #505F56; */
  font-size: 28px;
  font-family: 'PlayfairDisplay';
  /*font-family: "PlayfairDisplay";*/
  color: #4f5f56;
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
}

/* .form_auth_login_block p, .form_auth_password_block p{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;

    font-family: "Segoe UI Semibold";

    color: #526158;
} */
.form_auth_login_block,
.form_auth_password_block {
  /*display: flex;*/
  /*width: 280px;*/
  /*justify-content: center;*/
  margin: 15px auto;
  /*height: 39px;*/
  /*border: 1px solid var(--color-primary);*/
}
/*.auth_icon_block{*/
/*    display: flex;*/

/*    height: 40px;*/
/*    width: 30px;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.form_auth_button {
  display: block;
  width: 350px;
  height: 44px;
  margin: 5px auto;

  border: none;
  cursor: pointer;
  background-color: #4f5f56;
  color: white;
  font-size: 20px;
}

#message {
  margin-left: 10;
  margin-right: 10;
  margin-bottom: 10px;
  color: #907757;
}

.footer {
  position: absolute;
  bottom: 0px;
}

.footer_img {
  background: url('../images/login_view_img1.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 313px;
  height: 222px;
  margin: auto;
  margin-top: 70px;
  /* 
    position: absolute;
    bottom: 0px; */
}
.footer_text {
  font-size: 11px;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  position: relative;
  bottom: 12px;
  color: #4f5f56;
}

.form_auth_login_block:hover,
.form_auth_password_block:hover {
  border-color: cornflowerblue;
}

.form_auth_button:hover {
  background-color: grey;
}
.form_auth_button:active {
  background-color: var(--color-primary);
}
